<template>
  <div class='esign-main-wrapper'>
    <div class='onboarding-completed-text'>{{ h1Text }}</div>
    <div class='onboarding-completed-text-2'>{{ h2Text }}</div>
    <div class='onboarding-completed-text-3'>{{ paragraphText }}</div>
    <div class="account-statement-file-with-download" style="margin: 20px auto;">
      <div>
        <file-pdf-outlined
          :style="{
            fontSize: '20px',
            marginRight: '10px',
            color: 'red',
          }"
        />
      </div>
      <div>{{ ddpi.document.name }}</div>
      <div style="margin-left: 50px">
        <a :href="ddpi.document.link" target="_blank"
          ><download-outlined
            :style="{
              fontSize: '20px',
              color: '#CC4B4C',
              cursor: 'pointer',
            }"
        /></a>
      </div>
    </div>
    <div class='onboarding-completed-text-3'>{{ ddpi.information }}</div>
    <div class='onboarding-completed-text-3' style="text-align: left;">
      <div>
        <span style="font-weight: bold;">Authorized Person:</span> {{ ddpi.authorizedPerson }}
      </div>
      <div>
        <span style="font-weight: bold;">Address:</span> {{ ddpi.courierAddress }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import { useStore } from 'vuex';
import services from '../../../services/apis';

export default {
  components: {
    FilePdfOutlined,
    DownloadOutlined,
  },
  setup() {
    const store = useStore();
    const h1Text = ref('');
    const h2Text = ref('');
    const paragraphText = ref('');
    const ddpiInformation = `As per new SEBI regulation, it is mandatory for new clients to send this DDPI document duly signed, 
      and sent to the DP address physically to initiate DEMAT account opening process.`;
    const ddpi = ref({
      information: ddpiInformation,
      courierAddress: '',
      authorizedPerson: '',
      document: {
        name: '',
        link: '',
      },
    });
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          if (response.data.data.ddpi) {
            ddpi.value.courierAddress = response.data.data.ddpi.courierAddress;
            ddpi.value.authorizedPerson = response.data.data.ddpi.authorizedPerson ? response.data.data.ddpi.authorizedPerson : '';
          }

          const ddpiDocument = response.data.data.prefill.documents.find(
            document => document.documentType === 'DDPI_DRAFT'
          );

          if (ddpiDocument) {
            ddpi.value.document = {
              link: ddpiDocument.url,
              name: ddpiDocument.name,
            };
          }

          if (response.data.data.messages) {
            const messageData = response.data.data.messages;
            const responseData = messageData.reduce((initialValue, itr) => {
              initialValue[itr.style] = itr.text;
              return initialValue;
            }, {});
            h1Text.value = responseData.h1;
            h2Text.value = responseData.h2;
            paragraphText.value = responseData.p;
          }
          if(response.data.data.prefill.status === 'ACTIVE')
          {
            store.dispatch('onboardingStore/updateStatus', response.data.data.prefill.status);
          }
        })
        .catch(() => {});
    });
    return {
      h1Text,
      h2Text,
      paragraphText,
      ddpi,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/esignCompleted.scss';
</style>
